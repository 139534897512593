"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var defaults_1 = require("./defaults");
var helpers_1 = require("./helpers");
var Parlx = /** @class */ (function () {
    function Parlx(element, settings, callbacks) {
        var _this = this;
        if (settings === void 0) { settings = {}; }
        if (callbacks === void 0) { callbacks = {}; }
        this.onWindowScroll = function () {
            if (_this.element) {
                _this.parallaxEffect();
                if (typeof _this.callbacks.onScroll === 'function') {
                    _this.callbacks.onScroll(_this.element);
                }
            }
        };
        this.onWindowResize = function () {
            if (_this.element) {
                _this.parallaxEffect();
                if (typeof _this.callbacks.onResize === 'function') {
                    _this.callbacks.onResize(_this.element);
                }
            }
        };
        this.element = element;
        this.settings = this.extendSettings(settings);
        this.callbacks = callbacks;
        if (typeof this.callbacks.onInit === 'function') {
            this.callbacks.onInit(this.element);
        }
        this.speed = this.settings.speed;
        this.movement = 0;
        this.transform = '';
        this.scrolled = 0;
        this.parallaxEffect();
        this.addEventListeners();
    }
    Parlx.prototype.addEventListeners = function () {
        this.settings.base.addEventListener('scroll', this.onWindowScroll);
        window.addEventListener('resize', this.onWindowResize);
    };
    Parlx.prototype.removeEventListeners = function () {
        this.settings.base.removeEventListener('scroll', this.onWindowScroll);
        window.removeEventListener('resize', this.onWindowResize);
    };
    Parlx.prototype.destroy = function () {
        if (typeof this.callbacks.onDestroy === 'function') {
            this.callbacks.onDestroy(this.element);
        }
        this.removeEventListeners();
        this.element.parlx = null;
        delete this.element.parlx;
        this.element = null;
    };
    Parlx.prototype.transforms = function () {
        var moveX = 0;
        var moveY = 0;
        if (this.settings.direction === 'horizontal') {
            moveX = this.movement;
            moveY = 0;
        }
        else if (this.settings.direction === 'vertical') {
            moveX = 0;
            moveY = this.movement;
        }
        else if (this.settings.direction === 'diagonal') {
            moveX = this.movement;
            moveY = this.movement;
        }
        this.transform = "translate(" + moveX + "px, " + moveY + "px)";
    };
    Parlx.prototype.updateScrolled = function () {
        var axis = this.settings.axis.toLowerCase();
        if (helpers_1.bounceDetector(this.settings.base, axis)) {
            this.scrolled = this.element.getBoundingClientRect()[axis === 'y' ? 'top' : 'left'];
        }
    };
    Parlx.prototype.updatePosition = function () {
        if (this.settings.type === 'foreground') {
            Object.assign(this.element.style, {
                transform: this.transform
            });
        }
        else if (this.settings.type === 'background') {
            var child = this.element.querySelector('.parlx-children');
            if (!child) {
                child = document.createElement('div');
                child.classList.add('parlx-children');
                this.element.appendChild(child);
            }
            var absoluteScaleX = Math.abs(window.innerHeight * this.speed);
            var absoluteScaleY = Math.abs(window.innerWidth * this.speed);
            Object.assign(child.style, {
                height: this.element.offsetHeight + absoluteScaleX * 2 + "px",
                width: this.element.offsetWidth + absoluteScaleY * 2 + "px",
                transform: this.transform,
                objectFit: 'cover'
            });
        }
    };
    Parlx.prototype.parallaxEffect = function () {
        this.element.style.height = this.settings.height.toString();
        this.updateScrolled();
        if (Math.abs(this.speed) > 1) {
            this.speed = 0.3;
        }
        this.movement = (this.speed * this.scrolled) / 2;
        if (helpers_1.excludePlatform(this.settings.exclude)) {
            this.speed = 0;
        }
        this.transforms();
        this.updatePosition();
        var values = {
            move: this.movement
        };
        this.element.dispatchEvent(new CustomEvent('parlxMove', {
            detail: values
        }));
    };
    Parlx.prototype.extendSettings = function (settings) {
        var newSettings = {};
        var property;
        for (property in defaults_1.defaultSettings) {
            if (property in settings) {
                newSettings[property] = settings[property];
            }
            else if (this.element.getAttribute("data-parlx-" + property)) {
                var attribute = this.element.getAttribute("data-parlx-" + property);
                try {
                    newSettings[property] = JSON.parse(attribute);
                }
                catch (_a) {
                    newSettings[property] = attribute;
                }
            }
            else {
                newSettings[property] = defaults_1.defaultSettings[property];
            }
        }
        return newSettings;
    };
    Parlx.init = function (data) {
        if (data === void 0) { data = {}; }
        var elements = data.elements, settings = data.settings, callbacks = data.callbacks;
        if (elements instanceof Node) {
            elements = [elements];
        }
        if (elements instanceof NodeList) {
            elements = __spreadArrays(elements);
        }
        for (var _i = 0, elements_1 = elements; _i < elements_1.length; _i++) {
            var element = elements_1[_i];
            if (!('parlx' in element)) {
                return (element.parlx = new Parlx(element, settings, callbacks));
            }
        }
    };
    return Parlx;
}());
exports.default = Parlx;
if (typeof document !== 'undefined') {
    window.Parlx = Parlx;
    var elements = document.querySelectorAll('[data-parlx]');
    if (elements.length) {
        Parlx.init({ elements: elements });
    }
}
if (window.jQuery) {
    var $_1 = window.jQuery;
    $_1.fn.parlx = function (data) {
        if (data === void 0) { data = {}; }
        return Parlx.init({
            elements: this,
            settings: data.settings || {},
            callbacks: data.callbacks || {}
        });
    };
}
