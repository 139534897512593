"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultSettings = {
    axis: 'Y',
    base: window,
    direction: 'vertical',
    exclude: null,
    height: '400px',
    speed: 0.3,
    type: 'background'
};
